#contact .inner{
    width:600px;
    max-width: 100%;
    margin: auto;
    height:100%;
}

#contact .inner .m-title{
    color: #EC6726;
    text-align: center;
    font-weight: bold;
    text-align: center;
}
.contact-container{
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.contact{
    display: flex;
    height: 40px;
    align-items: center;
}

.contact-title h4{
    color: #EC6726;
    font-weight: bold;
    font-size: 15px;
    float: left;
    margin-bottom: 0px !important;
}

.info-icon{
    position: absolute;
    right: 14px;
    font-size: 20px;
    color: #EC6726;
    font-weight: bold;
    z-index: 0;
}

.info-label{
    color: #000;
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 15px;
}

.ifo-itms{
    padding-top: 20px;
    width: 90%;
    margin: auto;
}

.info-text{
    color: #EC6726;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 14px;
}