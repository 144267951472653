#notification .inner{
    width:600px;
    max-width: 100%;
    margin: auto;
    height:100%;
}

#notification .inner .m-title{
    color: #710493;
    text-align: center;
    font-weight: bold;
    text-align: center;
}

.notes{
    width: 95%;
    margin: auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
}

.notes .note h4{
    color: #000;
    text-align: left;
    font-weight: bold;
    margin: 0;
}

.notes .note div small{
    font-size: 10px;
    margin-bottom: 20px;
}

.caption-text{
    color: #710493;
    text-align: center;
    font-weight: normal;
    background-color: rgba(255,255,255,0.5);
    padding: 10px 5px;
    
}
